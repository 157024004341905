.servicio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f2f2f2;
    
    h1 {
      font-size: 24px;
      margin-bottom: 20px;
    }
    
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      input {
        width: 300px;
        height: 40px;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
      }
      
      button {
        width: 200px;
        height: 50px;
        background-color: rgb(0, 26, 128);
        color: #fff;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }
    }
    
    a {
      margin-top: 20px;
      color: rgb(0, 26, 128);
      text-decoration: none;
      font-size: 16px;
    }
  }
  