.user {
    display: flex;
    width: 100%;
    background-color: rgba(18, 120, 189, 0.05);
    .userContainer {
      flex: 6;
      &.collapsed {
        flex: 14;
      }
    }
  }
  