.notifications-container {
  margin: 20px;
}

.notification-form {
  display: flex;
  flex-direction: column;
  margin: 20px 100px 20px 100px;
  padding: 100px;
  border: 1px solid #4678C3;
  .label-title-form{
    font-size: 24px;
    color: #4678C3;
    margin-bottom: 20px;
  }
}

.button-container {
  display: flex;
  justify-content: space-between; /* Alinea los elementos al principio y al final */
  align-items: center; /* Alinea verticalmente al centro */
}

.label-title {
  margin: 20px; /* Agrega margen izquierdo a los títulos */
  color: #0E77BD;
  padding-top: 30px;
  padding-left: 30px;
  font-size: 35px;
}

.input-notis {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
}

.radio-group {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.radio-group label {
  margin-left: 10px;
}

.view-notifications-button {
  background-color: #4677c2;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-right: 20px;
  margin-left: 20px;
}

.view-notifications-button:hover {
  background-color: #345b8c;
}

.submit-button {
  align-self: flex-end;
  background-color: #4677c2;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 25%;
}

.submit-button:hover {
  background-color: #345b8c;
}

.notifications {
  margin-top: 20px;
  padding: 20px;
  margin-right: 80px;
}

.notification-item {
  margin-bottom: 30px; /* Increased margin to add separation */
  padding-bottom: 10px;
  padding: 50px;
  list-style-type: none; /* Remove bullet point */
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 3px 0px #e6e8eb;
  border-radius: 10px;
  color: #495057;
  .li-item {
    margin-bottom: 15px;
  }
}

.notification-item strong {
  font-weight: bold;
}

.notification-item .notification-details {
  margin-top: 5px;
}

.notification-item .notification-details .destination,
.notification-item .notification-details .date {
  font-family: Arial, sans-serif;
}

.notification-item .notification-details .destination {
  margin-right: 10px;
}

.notificacion-view-container {
  margin-bottom: 10px;
}

.showNotificationView-text {
  color: rgba(14, 119, 189, 1);
  padding-top: 30px; /* ajusta según tus necesidades */
  padding-left: 30px; /* ajusta según tus necesidades */
  font-size: 35px;
}

/* Modifica estos estilos en tu archivo Notifications.scss */

.view-notifications-button-container,
.notification-view-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px; /* Ajusta según tus necesidades */
  margin-right: 100px; /* Ajusta según tus necesidades */
  width: 100%;
}

.view-notifications-button {
  background-color: #4677c2;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-left: 20px;
}

.view-notifications-button:hover {
  background-color: #345b8c;
}

.notification-view-button {
  background-color: #33a186;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-right: 20px; /* Alinea automáticamente a la derecha */
}

.notification-view-button:hover {
  background-color: darkgreen;
}

.notification-form-container{
  min-height: 100vh;
}


