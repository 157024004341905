/* Estilos para el contenedor principal */
.privacy-policy-container {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #e0e0e0;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Estilos para los títulos */
.privacy-policy-container h2 {
    color: #333;
    border-bottom: 2px solid #666;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.privacy-policy-container h3 {
    color: #444;
    margin-top: 25px;
    margin-bottom: 15px;
}

/* Estilos para el contenido de los párrafos */
.privacy-policy-container p {
    color: #666;
    line-height: 1.6;
    text-align: justify;
    margin-bottom: 20px;
}

/* Si deseas agregar un link en alguna parte de tu política, este es un estilo básico */
.privacy-policy-container a {
    color: #0077cc;
    text-decoration: none;
    transition: color 0.3s;
}

.privacy-policy-container a:hover {
    color: #0055aa;
}
