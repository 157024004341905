.update-servicio {
  display: flex;
  flex-direction: column;
//   align-items: flex-start;
  margin: 20px;
  
  .top-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    h1 {
      margin: 0;
      margin-right: 10px;
    }

    .blue-button {
      background-color: rgb(0, 26, 128);
      color: white;
      margin-top: 10px;
    }

    .volver-link {
      margin-top: 10px;
    }
  }

  h1 {
    margin: 0;
    font-size: 24px;
  }

  button {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: rgb(0, 26, 128);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .datos-servicio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    margin-right: 50%;
    padding: 20px;
    background-color: rgb(144, 180, 238);

    h2 {
      margin: 0;
      font-size: 20px;
    }

    form {
      margin-top: 10px;

      label {
        display: block;
        margin-bottom: 10px;
        font-weight: bold;
      }

      input[type="text"],
      input[type="email"] {
        width: 200px;
        padding: 5px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }

      button[type="submit"] {
        margin-top: 10px;
        background-color: rgb(0, 26, 128);
        color: white;
        padding: 5px 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }

  .admins-servicio {
    margin-top: 20px;
    padding: 20px;
    background-color: lightblue;

    h2 {
      margin: 0;
      font-size: 20px;
    }

    ul {
      margin-top: 10px;
      padding-left: 20px;

      li {
        margin-bottom: 5px;
      }
    }
  }

  .add-admin {
    margin-top: 20px;
    padding: 20px;
    background-color: rgb(144, 180, 238);
    margin-right: 50%;

    h2 {
      margin: 0;
      font-size: 20px;
    }

    form {
      margin-top: 10px;

      label {
        display: block;
        margin-bottom: 10px;
        font-weight: bold;
      }

      input[type="email"] {
        width: 200px;
        padding: 5px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }

      button[type="submit"] {
        margin-top: 10px;
        background-color: rgb(0, 26, 128);
        color: white;
        padding: 5px 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }

  .volver-link {
    margin-top: 20px;
    color: rgb(0, 26, 128);
  }
}
