.data-grid-container {
  background-color: #fff;
  height: 600px;
  padding: 20px;
  margin: 10px;

  .title-registro {
    color: #4678c3;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 75px;
  }
}
  .MuiDataGrid-root .MuiDataGrid-row {
    &:nth-of-type(odd) {
      background-color: rgba(234, 239, 242, 0.5) !important;
    }
    &:nth-of-type(even) {
      background-color: white !important;
    }
  }
.button-container {
  display: flex;

  .search-container {
    display: flex;
    align-items: center;
    margin: 10px;
    justify-content: flex-end;
  }

  .add-container {
    display: flex;
    align-items: center;
    margin: 10px;
    justify-content: center;
    flex: 1; /* Hace que el contenedor de agregar ocupe todo el espacio restante */
  }
}

.input-search {
  flex: 1; /* El campo de búsqueda ocupará todo el espacio restante */
  max-width: 200px; /* Ancho máximo que se puede expandir */
  text-decoration: none;
  color: green;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid green;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.link {
  text-decoration: none;
  color: green;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid green;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.cellAction {
  display: flex;
  align-items: center;
  gap: 15px;

  .updateButton {
    padding: 2px 5px;
    border-radius: 5px;
    color: #fff;
    border: 1px dotted rgba(122, 164, 192, 0.596);
    cursor: pointer;
    background-color: rgba(122, 164, 192, 1);
  }

  .deleteButton {
    padding: 2px 5px;
    border-radius: 5px;
    color: crimson;
    border: 1px dotted rgba(220, 20, 60, 0.6);
    cursor: pointer;
  }
}
