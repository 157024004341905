
.add-user-container {
    padding: 20px;
    h1 {
      color: rgb(70, 120, 195); // Azul primario, puedes cambiar el color según tus preferencias
      padding-bottom: 10px;
    } 
    .form-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      .form-control {
        width: 100%; // Asegurar que cada control ocupe el 100% de su celda
        background-color: #fff;
    }
      .MuiFormControl-root {
        width: 100%;
      }
      .MuiButton-root {
        margin-top: 20px;
      }
    }
  }
  