.navbar {
  height: 83px;
  border-bottom: 0.5px solid rgb(231, 228, 228);
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  background-color: white;

  .wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between; // Cambiado de space-between a flex-end

    .search {
      display: flex;
      align-items: center;
      border: 0.5px solid lightgray;
      padding: 3px;
      border-radius: 10px;
      input {
        border: none;
        outline: none;
        background: transparent;

        &::placeholder {
          font-size: 12px;
        }
      }
    }
    .iconMenu {
      font-size: 40px;
      cursor: pointer;
    }
    .items {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;

        .icon {
          font-size: 30px;
          color: #7aa4c0;
        }

        .avatar {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background-color: #0055aa;
          color: white;
        }

        .counter {
          width: 17px;
          height: 17px;
          background-color: rgba(70, 120, 195, 1);
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
        }
        .counter2 {
          width: 17px;
          height: 17px;
          background-color: #33a186;
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
        }
        .user {
          margin-left: 10px;
          color: rgba(70, 120, 195, 1);
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          cursor: pointer;
        }
        .overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(
            0,
            0,
            0,
            0.5
          ); // Oscurecimiento con 50% de opacidad
          z-index: 999; // Asegúrate de que esté detrás del menú desplegable pero encima de otros elementos
          display: none; // Por defecto, el overlay está oculto

          &.active {
            display: block; // Muestra el overlay cuando está activo
          }
        }
      }
    }
  }
}
