.download-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &.open {
    display: flex;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 15%; /* Ajusta según tu preferencia */
    max-width: 500px; /* Establece un máximo si es necesario */
    height: auto; /* Permite que la altura se ajuste automáticamente al contenido */

    p {
      margin: 10px 0; /* Espaciado entre los párrafos */
    }

    button {
      margin-top: 20px;
      padding: 8px 16px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}
