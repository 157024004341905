.home {
  display: flex;

  .homeContainer {
    flex: 6;
    display: flex;
    flex-direction: column;
    background-color: rgba(18, 120, 189, 0.05);
    padding: 20px;

    &.collapsed {
      flex: 14;
      display: flex;
    }

    .config-title {
      color: var(--text-color, #1278bd);
      margin: 20px 0;
      font-size: 24px;
    }

    .config-section {
      margin-bottom: 20px;
      padding: 20px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 8px;
    }

    .config-item {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;

      label {
        color: var(--text-color, #1278bd);
        font-weight: bold;
        margin-bottom: 10px;
      }

      input {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 16px;
      }
    }

    .user-greeting {
      color: var(--text-color, #1278bd);
      margin-left: 20px;
      margin-top: 20px;
    }

    .box-container {
      display: flex;
      margin-bottom: 20px;

      .service-info-box,
      .update-box {
        min-width: 500px;
        min-height: 300px;
        margin: 0 10px;
        background-color: white;
        padding: 10px;
      }

      .update-box {
        min-height: 550px;
      }
    }

    .widgets,
    .charts {
      display: flex;
      padding: 20px;
      gap: 20px;
    }

    .charts {
      padding: 5px 20px;
    }

    .listContainer {
      padding: 20px;
      margin: 20px;

      .listTitle {
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
      }
    }

    .recent-documents {
      flex-direction: column;
      align-items: flex-start;

      ul {
        list-style: none;
        padding: 0;
        border-top: 0.25px solid var(--text-color, #1278bd);
      }

      .document-item {
        display: flex;
        align-items: center;
        padding: 10px 0;

        &::before {
          content: "";
          width: 15px;
          height: 15px;
          background-color: var(--text-color, #1278bd);
          border-radius: 50%;
          margin-right: 15px;
        }

        .info {
          flex-grow: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 10px;
          width: 100%;

          .name-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            span {
              font-weight: bold;
              margin-bottom: 5px;
            }
          }

          p.date-text {
            margin-left: auto;
            margin-right: 0;
          }
        }
      }

      h2 {
        color: #1278bd;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 10px;
        line-height: 19.2px;
      }
    }
  }
}

.grafana-iframe-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.grafana-iframe-container > div {
  margin-bottom: 16px;
}

.grafana-iframe-container iframe {
  border: 1px solid #ddd;
  border-radius: 4px;
}