.formCreatorContainer {
  padding: 20px;

  .title-formulario {
    font-size: 35px;
    font-weight: 600;
    line-height: 75px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(14, 119, 189, 1);
  }
  input,
  select {
    width: 100%;
  }

  .input-options {
    display: flex;
    flex-direction: column;
    text-align: left !important;
    position: absolute; // Posición absoluta para las opciones
    background-color: rgb(200, 199, 199);
    padding: 10px;
    width: auto;
    max-width: 300px;
    display: inline-block;
    z-index: 10;

    .button-input {
      display: block;
      color: black;
      text-align: left !important;
      background: none;
      border: none;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .campo-title {
    color: rgba(14, 119, 189, 1);
    letter-spacing: 0.01em;
    size: 16px;
    margin-bottom: 10px;
  }
  .input-type-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px;
  }
  .input-color {
    color: #fff;
    background-color: rgba(14, 119, 189, 1);
    &:hover {
      background-color: rgba(45, 83, 140, 1); // Color blanco al pasar el cursor
      color: #fff; // Cambiando el color del texto a la tonalidad azul original para que siga siendo visible
    }
  }
  .sections {
    display: flex;

    .left-section {
      flex: 1.5;
      padding: 20px;

      /* Estilos para los inputs superiores */
      > *:nth-child(n + 2):nth-child(-n + 4) {
        background-color: rgba(70, 120, 195, 1);
        color: white;
      }

      .action-button {
        background-color: rgba(51, 161, 134, 1);
        color: white;
        &:hover {
          background-color: rgba(
            41,
            141,
            114,
            1
          ); // Verde más oscuro en el hover
        }
      }

      .form-name-row {
        width: 100%;
        display: flex;
        flex-direction: column; // Para que los elementos estén uno debajo del otro
        margin-bottom: 20px; // Espacio adicional debajo
        #name {
          background-color: #fff;
        }
      }

      .form-input-row {
        display: flex;
        align-items: center;
        width: 100%;

        .input-name {
          flex: 0.6;
          background-color: #fff;
        }

        .input-type {
          flex: 0.3;
          margin-left: 2%;
          background-color: #fff;
        }
      }
    }

    .right-section {
      flex: 1;
      padding: 20px;
    }
  }

  .right-align-text {
    text-align: right;
  }

  .section-title {
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  .option-list {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      button {
        background-color: #e74c3c;
        color: #fff;
        border: none;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
          background-color: #c0392b;
        }
      }
    }
  }
}

// Añade cualquier otro estilo que consideres necesario.
