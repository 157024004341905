.user-dropdown {
  position: absolute;
  top: 60px; // Ajusta según la altura de tu navbar
  right: 20px;
  background-color: #fff;
  border-radius: 5px;
  z-index: 10;
  color: rgba(70, 120, 195, 1) !important;
  a {
    text-decoration: none; // Elimina la línea debajo
    color: rgba(70, 120, 195, 1) !important;
    &:hover {
      text-decoration: underline; // Opcional: subraya el enlace al pasar el ratón por encima
    }
    &:active {
      color: rgba(
        70,
        120,
        195,
        1
      ); // Mantiene el color azul incluso cuando se pulsa
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      cursor: pointer;
      color: rgba(70, 120, 195, 1) !important;
      &:hover {
        background-color: #f5f5f5;
      }
      .menu-icon {
        margin-right: 10px;
      }
    }
  }
}
