.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semi-transparente */
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
  }
  
  .modal-content {
    background-color: white;
    padding: 60px 100px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  
    h2 {
      margin-bottom: 20px;
    }
  
    form {
      display: flex;
      flex-direction: column;
  
      label {
        margin-bottom: 10px;
      }
  
      input[type="time"] {
        margin-bottom: 10px;
      }
    }
  
    button[type="submit"] {
      align-self: flex-end;
    }
  }
  