.icon-picker {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
    margin-top: 10px;
    background-color: rgba(14, 119, 189, 1);
  
    &:hover {
      background-color: rgba(45, 83, 140, 1);
      color: #fff;
    }
  
    .selected-icon {
      width: 100px;
      height: 50px;
      border: 1px solid #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 16px;
      background-color: rgba(14, 119, 189, 1);
      color: #fff;
  
      &-img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        padding: 5px;
        background-color: #fff;
      }
    }
  
    .icon-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 5px;
      position: absolute;
      top: 60px;
      left: 0;
      border: 1px solid #ccc;
      background-color: #fff;
      padding: 5px;
      z-index: 1000;
  
      img {
        width: 30px;
        height: 30px;
        cursor: pointer;
        margin: 10px;
        border-radius: 50%;
        padding: 5px;
        background-color: #fff;
        box-sizing: content-box;
      }
    }
  }
  