.profile-container {
  width: 100%;
  max-width: 1200px; /* Puedes ajustar este valor según tus necesidades */
  .user-section {
    text-align: center;
    padding: 10px;
    .user-header {
      display: flex;
      align-items: center;
      justify-content: left;
      margin-bottom: 20px;
      background-color: rgb(70, 120, 195);
      .user-photo {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-right: 100px;
        margin-left: 200px;
        border: 4px solid #fff; // Borde blanco
        background-color: #fff; // Fondo blanco
        margin: 30px;
        // Estilos para el logo (si es el icono)
        &.MuiSvgIcon-root {
          font-size: 80px;
          color: #fff;
          border-radius: 50%; // Añadido para asegurar que el icono también sea un círculo
        }
      }

      .default-user-icon {
        font-size: 100px;
        margin-right: 20px;
        margin-left: 200px;
        border-radius: 50%; // Añadido para asegurar que el icono también sea un círculo
        background-color: #fff; // Fondo blanco
        color: rgb(70, 120, 195);
        margin: 30px;
        padding: 30px;
      }

      .default-user-icon {
        font-size: 100px;
        margin-right: 100px;
        margin-left: 200px;
      }

      h2 {
        color: #fff;
        margin: 0;
        font-size: 40px;
      }
    }
  }
}

.addButton {
  margin-bottom: 20px;
}

.form-container {
  padding: 40px;

  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  .field-container {
    flex-basis: calc(50% - 0.5rem);
    margin-bottom: 1rem;
    box-sizing: border-box;

    .upload-button {
      display: flex;
      align-items: center;
      text-align: center;
      gap: 0.5rem;
      cursor: pointer;
      color: #000000;
      padding: 8px;
      border: none;
      border-radius: 4px;
      padding: 10px;
      &:hover {
        background-color: #87929e;
      }

      input[type="file"] {
        display: none;
      }
    }
  }

  .small {
    height: 50px;
    font-size: 15px;
  }

  .left-section {
    flex-basis: 100%;
    text-align: center;
  }

  .right-section {
    flex-basis: calc(50% - 0.5rem);
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
  }
}

.addButton {
  margin-bottom: 20px; /* Ajusta el valor según tus necesidades */
}
