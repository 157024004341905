.sidebar {
  position: relative;
  flex: 1;
  border-right: 0.5px solid rgb(230, 227, 227);
  min-height: 50vh;
  width: 250px;
  min-height: 100vh;
  height: auto;

  background-color: var(--sidebar-bg-color, rgba(70, 120, 195, 1));
  color: var(--sidebar-text-color, #ffffff);

  &.collapsed {
    width: 70px; // un ancho más pequeño cuando esté contraído

    .top{
      background-color: rgba(70, 120, 195, 1);
    }
    .logo,
    .link-text,
    .privacy-policy-link a {
      display: none; // oculta el texto y el logo cuando esté contraído
    }

    .icon {
      margin: 0 auto; // centra los íconos cuando el texto no está presente
      font-size: 40px !important;
    }
  }
  .top {
    height: 83px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

    .logo {
      font-size: 30px;
      font-weight: bold;
      color: var(--text-color, rgba(70, 120, 195, 1));
      margin-right: 10px; // Ajusta el margen derecho según sea necesario
      height: 30px; // Ajusta la altura según sea necesario
      width: auto; // Mantenemos el ancho proporcional
      img {
        margin-right: 10px; // Ajusta el margen derecho según sea necesario
        height: 61px; // Ajusta la altura según sea necesario
        width: auto; // Mantenemos el ancho proporcional
        margin-bottom: 4px; // Ajusta el margen inferior para centrar las letras
        vertical-align: middle; // Alinea verticalmente con la imagen
      }
    }
    .logoCollapsed {
      font-size: 30px;
      font-weight: bold;
      color: var(--text-color, rgba(70, 120, 195, 1));
      height: 30px; // Ajusta la altura según sea necesario
      width: auto; // Mantenemos el ancho proporcional
      img {
        height: 61px; // Ajusta la altura según sea necesario
        width: auto; // Mantenemos el ancho proporcional
        margin-bottom: 4px; // Ajusta el margen inferior para centrar las letras
        vertical-align: middle; // Alinea verticalmente con la imagen
      }
    }
  }

  hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
  }

  .center {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Alinea en el centro verticalmente */

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 10px;
        font-weight: bold;
        color: #999;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        font-size: 14px;
        font-weight: 100;
        line-height: 20px;
        letter-spacing: 0.7px; // Ajusta el valor según sea necesario
        color: #999; // Agregando color a los títulos
        margin-top: 15px;
        margin-bottom: 5px;
        cursor: pointer;

        &:hover {
          background-color: var(--sidebar-hover-color, #ffffff);
          border-radius: 5px;
          .icon,
          span {
            color: var(--text-color, rgb(70, 120, 195, 1));
          }
        }

        .icon {
          font-size: 18px;
          color: #ffffff;
        }

        span {
          font-size: 13px;
          font-weight: 600;
          color: #ffffff;
          margin-left: 10px;
        }
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px; // Ajusta este valor según sea necesario

    .privacy-policy-link {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.6px;
      text-align: center;
      margin-top: 10px;
      a {
        text-decoration: none;
        color: white;
      }
    }
    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #7451f8;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: whitesmoke;
      }
      &:nth-child(2) {
        background-color: #333;
      }
      &:nth-child(3) {
        background-color: darkblue;
      }
    }
  }
}
