.config-container {
    .usuarios-title {
        color: rgb(70, 120, 195); // Ajusta este color azul según tus necesidades
        font-size: 32px; // Tamaño de fuente más grande
        padding: 40px 50px;
    }
    .paperContainer{
        padding: 7px 50px; 
        .user-paper {
            padding: 30px; // Padding para las cajas de usuario
            margin-bottom: 10px; // Espacio entre las cajas
            .user-info {
                font-size: 1rem; // Ajusta el tamaño de fuente según tus necesidades
                &.name {
                    font-size: 1.25rem; // Tamaño de fuente para el nombre
                }
            }
        }
    }
    .buttonAdd {
        text-align: right;
        margin-right: 30px;
        margin-top: 10px; // Espacio arriba de los botones
    }
}
