.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(70, 120, 195, 1);
  position: relative; // Para posicionar absolutamente el fondo respecto a este contenedor
  max-width: 100vw; // Asegura que el contenedor no sea más ancho que la ventana
  max-height: 100vh; // Asegura que el contenedor no sea más alto que la ventana
  overflow: hidden; // Oculta cualquier contenido que se desborde

  &::before {
    content: "";
    background-image: url("../../images/background-logo.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 1052px;
    height: 885px;
    top: calc(100% - 1052px + 200px); // Ajusté para bajar la imagen un poco
    right: 20px; // Ajusté para mover la imagen un poco a la izquierda
  }
  .branding {
    position: absolute;
    top: 100px; // Ajusta este valor para modificar el margen superior
    left: 100px; // Ajusta este valor para modificar el margen a la izquierda
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px; // Espacio entre el logo y el texto "SENTRY"

    .branding-logo {
      width: 75px; // Ajusta según el tamaño que desees para el logo
      height: auto;
    }

    .branding-title {
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
  .loginContainer {
    background-color: white;
    width: 409px;
    height: 453px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px; // Añade un borde redondeado al contenedor
    padding: 20px; // Padding alrededor del contenedor
    margin-top: 100px;
    .loginTitle {
      font-family: Raleway;
      font-size: 35px;
      font-weight: 400;
      line-height: 50px;
      letter-spacing: 0.01em;
      text-align: center;
      color: rgba(70, 120, 195, 1); // Azul
      margin-bottom: 20px; // Espaciado entre el título y los inputs
    }

    .loginForm {
      display: flex;
      flex-direction: column;
      gap: 16px; // Espaciado entre los inputs y el botón

      .loginInput {
        width: 322px;
        height: 25px;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid #ccc; // Puedes cambiar esto para ajustar el color del borde
        background-color: white;
        position: relative; // Añadir esto para posicionar correctamente el pseudo-elemento
        
        &::after {
            content: "";
            position: absolute;
            right: 0;    // Posicionado a la derecha
            top: 0;      // Desde la parte superior del input
            bottom: 0;   // Hasta la parte inferior del input
            width: 10%;  // Ocupa el 10% del ancho del input
            background-color: rgba(198, 198, 200, 1);
            pointer-events: none;  // Para evitar interacciones
        }
        &:focus {
          outline: none;
          border-color: rgba(
            70,
            120,
            195,
            1
          ); // Resalta el borde en azul cuando se enfoca
        }
      }

      .loginButton {
        width: 360px;
        height: 40px;
        background-color: rgba(70, 120, 195, 1);
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: 0.3s;
        z-index: 10;
        &:hover {
          background-color: rgba(
            50,
            100,
            175,
            1
          ); // Oscurece el botón un poco cuando se pasa el cursor sobre él
        }
      }
    }

    .forgotPassword {
      margin-top: 20px; // Espaciado entre el botón y el texto
      color: rgba(70, 120, 195, 1);
      cursor: pointer;
      z-index: 10;
      &:hover {
        text-decoration: underline; // Subraya el texto cuando se pasa el cursor sobre él
        cursor: pointer;
      }
    }
  }
}
