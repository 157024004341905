.edit-vigilante-container {
    padding: 20px;
  
    // Estilos para el encabezado
    .header {
      background: #4678c3;
      color: white;
      padding: 20px;
      text-align: center;
  
      h1 {
        font-size: 36px;
        margin-bottom: 10px;
      }
    }
  
    // Estilos para el calendario
    .calendar {
      margin-top: 20px;
    }
  }
  