.phone-container {
  position: relative;
  display: inline-block;
  max-width: 400px; /* Establece un máximo de ancho para la pantalla del móvil */
  max-height: 500px; /* Establece un máximo de altura para la pantalla del móvil */

  ::placeholder {
    color: rgb(0, 0, 0);
  }
}

.phone-container img {
  width: 200%;
  max-width: 100%; /* Asegura que la imagen no se estire más allá del contenedor */
}

.selected-input-container {
  border: 2px solid #efefef; /* Borde azul claro */
  border-radius: 8px; /* Esquina redondeada */
  padding: 10px; /* Espaciado interno */
}

.form-preview {
  position: absolute;
  top: 50%; /* Posiciona en la mitad superior */
  left: 50%; /* Posiciona en el centro */
  transform: translate(-50%, -50%); /* Centra el contenido */
  width: 70%; /* Ancho del formulario */
  padding: 10px;
  border-radius: 10px;
  color: white;
  max-height: 420px;
  overflow-y: auto;
}
.circular-button {
  background-color: rgba(70, 120, 195, 1);
  color: #fff;
  margin-top: 20px;
  border-radius: 24px !important;
  width: 140px !important;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 20px;
  right: 20px;
  font-size: 12px !important; /* Cambia el tamaño del texto si es necesario */
  border: 1px solid #ccc; /* Puedes ajustar el color del borde si es necesario */
  gap: 10px;
  margin-left: 30px;
}

.form-preview h3 {
  margin-top: 5px;
  text-align: center;
  margin-bottom: 10px;
}

.form-preview ul {
  padding: 0;
  list-style-type: none;
}

.form-preview li {
  margin-bottom: 10px;
}

.form-preview label {
  font-weight: bold;
  font-size: 12px;

}

.form-preview select,
.form-preview input {
  width: 100%;
  padding: 10px 0; /* Ajusta el espacio vertical para solo una barra blanca abajo */
  border: 1px solid #ccc; /* Agrega solo la barra blanca abajo */
  border-radius: 5px; 
  background-color: #fff;
  font-size: 10px;
  // background: none; /* Fondo transparente */
}

.form-preview button {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 5px;
  color: white;
}
