.formInput {
  margin-bottom: 20px; /* Espaciado entre elementos de formulario */
  width: 25%; /* Ancho completo */

}

/* Estilos para los selects */
.formInput .MuiFormControl-root {
  width: 100%; /* Ancho completo */
}

/* Estilos para los botones */
.button-container {
  display: flex;
  justify-content: flex-end; /* Alinear a la derecha */
}

/* Estilos para el botón */
.button-container button {
  margin-left: 10px; /* Espacio entre el botón y otros elementos */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semi-transparente */
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  z-index: 9999; /* Asegura que el modal esté por encima del resto de contenido */
}

.modal-content {
  background-color: white;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative; /* Ajusta la posición del contenido */
}

.modal-close {
  position: absolute;
  top: -200px;
  right: -120px;
  cursor: pointer;
  z-index: 99999; /* Asegura que el botón de cierre esté por encima del contenido del modal */
}
