.custom-scrollbar {
  overflow-x: auto;
  scrollbar-width: thin; /* Para Firefox */
  -ms-overflow-style: none; /* Para Internet Explorer 10+ */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 2px; /* Reducir el ancho para Chrome, Safari */
  height: 7px;
  background-color: rgba(230, 230, 230, 1);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: linear-gradient(0deg, #c6c6c8 0%, #c6c6c8 100%), #e6e6e6; /* Color del thumb */
  border-radius: 2px;
  margin-top: 20px; /* Margen en la parte superior del thumb */
  border-radius: 100px;
}
