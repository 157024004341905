.single {
  display: flex;
  width: 100%;
  .singleContainer {
    flex: 6;
    .top {
      padding: 20px;
      display: flex;
      gap: 20px;
      .left {
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: #fff;
        .item {
          display: flex;
          align-items: center;
          gap: 20px;
          max-width: 400px; /* Set your desired max-width */
          flex-shrink: 0; /* Prevent excessive shrinking */

          .itemImg,
          .MuiSvgIcon-root {
            width: 200px;
            height: 200px;
            border-radius: 50%;
            object-fit: cover;
            flex-shrink: 0;
            color: #4678c3;
          }

          .details {
            display: flex;
            flex-direction: column;
            margin-left: 30px;
            gap: 20px;
            font-size: 0.9rem;

            .itemTitle,
            p {
              margin: 0;
            }
          }
        }

        hr {
          margin: 10px 0;
          width: 100%;
          border: none;
          border-top: 1px solid #d4d4d4;
        }

        .contactInfo {
          display: flex;
          gap: 10px;
          align-items: center;

          .MuiSvgIcon-root {
            background-color: rgba(209, 212, 214, 1);
            color: white;
            border-radius: 50%;
            margin-top: 5px;
            padding: 5px;
            width: 25px;
            height: 25px;
          }
        }
        .detailItem {
          max-width: 400px; /* Set your desired max-width */
          flex-shrink: 0; /* Prevent excessive shrinking */
          .tiendasContainer {
            display: flex;
            flex-wrap: wrap; /* Allow items to wrap to the next line */
            gap: 10px;
          }

          .tiendaItem {
            background-color: #d4d4d4;
            border-radius: 25%;
            padding: 5px 10px;
            text-align: center;
          }
        }
      }
      .right {
        flex: 2;
      }
    }

    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 10px 20px;
    }

    .title {
      font-size: 16px;
      color: lightgray;
      margin-bottom: 20px;
    }
  }
}
