.chart {
  flex: 4;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  color: gray;

  .title {
    margin-bottom: 20px;
  }

  .chartGrid {
    stroke: rgb(228, 225, 225);
  }
}

.chart-wrapper {
  background-color: #fff;
  padding: 20px;
}

/* chart.scss */
.chart-container {
  background-color: white; /* Fondo blanco para el div que contiene todo */
  padding: 20px; /* Añade un padding para que el contenido no esté pegado a los bordes */
  margin: 20px;
  display: flex; /* Añade flex para centrar el contenido */
  justify-content: center; /* Centra el contenido horizontalmente */
}

.chart-content {
  width: 90%; /* Ajusta el ancho según lo que necesites, aquí se usa el 90% del contenedor */
  max-width: 1200px; /* Establece un ancho máximo si lo deseas */
}

.chart-title {
  color: rgba(108, 117, 125, 1); /* Color del título */
  font-weight: 600; /* Peso de la fuente */
  font-size: 16px; /* Tamaño de la fuente */
  line-height: 17.6px; /* Altura de línea */
  margin-left: 20px; /* Margen a la izquierda */
  margin-bottom: 10px;
}

/* chart.scss */
.custom-legend {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px; /* Aumenta el margen superior para separar más la leyenda del gráfico */
}

.custom-legend-item {
  margin: 0 10%; /* Aumenta el margen para separar aún más los items entre sí */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-legend-item > div {
  font-size: 18px; /* Mantiene el tamaño de fuente aumentado */
  margin-bottom: 10px; /* Aumenta el margen inferior para separar más el texto del número */
  color: inherit; /* Asegura que el color del número sea el mismo que el del título */
}

.custom-legend-item-title {
  color: inherit; /* Asegura que el color del título sea el mismo que el del número */
  margin-bottom: 15px; /* Aumenta el margen inferior para separar más el título del número */
}
